<template>
  <div>
    <v-card>
      <v-card-title>Listado de Materiales</v-card-title>
      <v-card-text>
        <botonera
          :botones="botones"
          alinear="right"
          @crear="dialog = !dialog"
          @cargar="upload = !upload"
        >
        </botonera>
      </v-card-text>
    </v-card>

    <datatable
      :cabeceras="listHeaders"
      :items="listBody"
      titulo="Listado de Materiales"
      label="Escriba Para buscar Materiales"
      icono="list"
      color_icono="azul"
      :tachar="false"
      :acciones="['editar', 'eliminar']"
      @editar="editMaterial($event)"
      @eliminar="deleteMaterial($event)"
    >
    </datatable>

    <!-- Modal Nuevo Acopio -->
    <v-dialog v-model="dialog" width="800px" @click:outside="closeModal">
      <v-card>
        <v-card-title class="blue darken-1">{{ title }}</v-card-title>
        <v-card-text>
          <form>
            <v-row class="mx-2">
              <v-col class="align-center justify-space-between" cols="4">
                <v-select
                  prepend-icon="mdi-factory"
                  v-model="item.categorias_id"
                  :items="categorias"
                  item-text="nombre"
                  item-value="id"
                  label="Categorias"
                  color="blue darken-3"
                  persistent-hint
                  :reduce="(item) => item.id"
                >
                </v-select>
              </v-col>
              <v-col class="align-center justify-space-between" cols="3">
                <v-text-field
                  prepend-icon="qr_code"
                  placeholder="Cod. Articulo"
                  v-model="item.codigo_articulo"
                >
                </v-text-field>
              </v-col>
              <v-col class="align-center justify-space-between" cols="3">
                <v-text-field
                  prepend-icon="badge"
                  placeholder="Nombre"
                  v-model="item.nombre"
                >
                </v-text-field>
              </v-col>
              <v-col class="align-center justify-space-between" cols="3">
                <v-text-field
                  prepend-icon="mdi-dialpad"
                  placeholder="Unidad Medida"
                  v-model="item.unidad_medida"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeModal">Cerrar</v-btn>
          <v-btn color="primary" @click="saveMaterial">Grabar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal upload -->
    <v-dialog v-model="upload" width="600px">
      <v-card>
        <v-card-title class="green darken-1">
          Subir listado de materiales desde excel
        </v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-file-input
              v-model="file"
              accept=".xls, .xlsx"
              label="Archivo Excel"
            ></v-file-input>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="upload = false">Cancelar</v-btn>
          <v-btn color="primary" @click="uploadMateriales">Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->

    <v-snackbar v-model="snackbar" :timeout="5000" :color="color">
      {{ validation_message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import DataTable from "@/components/base/DataTable.vue";

export default {
  data: () => ({
    title: "Nuevo Material",
    color: "green",
    snackbar: false,
    errors: [],
    validation_message: "",
    search: null,
    file: null,
    dialog: false,
    upload: false,
    changeState: false,
    empresas: [],
    colors: [],
    sizes: [],
    state: null,
    states: [],
    index: -1,
    categorias: [],
    item: {
      id: 0,
      codigo_articulo: "",
      nombre: "",
      categorias_id: null,
      unidad_medida: "",
    },
    listHeaders: [
      {
        text: "Id",
        align: "center",
        sortable: true,
        value: "id",
        class: "blue-grey darken-4 white-text",
      },
      {
        text: "Cod. Articulo",
        align: "center",
        sortable: true,
        value: "codigo_articulo",
        class: "blue-grey darken-4 white-text",
      },
      {
        text: "Nombre",
        align: "center",
        sortable: true,
        value: "nombre",
        class: "blue-grey darken-4 white-text",
      },
      {
        text: "Categoria",
        align: "center",
        sortable: true,
        value: "nombre_categoria",
        class: "blue-grey darken-4 white-text",
      },
      {
        text: "Unidad de Medida",
        align: "center",
        sortable: true,
        value: "unidad_medida",
        class: "blue-grey darken-4 white-text",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
        class: "blue-grey darken-4 white-text",
      },
    ],
    listBody: [],
    botones: [
      { boton: "crear", tooltip: "Nuevo Material" },
      { boton: "cargar", tooltip: "Carga Masiva" },
      //{boton:'eliminar',tooltip:'Borrar Todo'}
    ],
  }),
  mounted() {
    this.loadMateriales();
    this.loadCategorias();
  },
  methods: {
    async loadMateriales() {
      this.mostrarLoading("Cargando listado de áreas...");
      let url = `${this.base_url}materiales`;
      await this.axios
        .get(url, this.headers_json)
        .then((response) => {
          this.listBody = response.data;
          this.ocultarLoading();
        })
        .catch((error) => {
          console.log(error);
          this.ocultarLoading();
        });
    },
    async loadCategorias() {
      this.mostrarLoading("Cargando listado de áreas...");
      let url = `${this.base_url}categorias`;
      await this.axios
        .get(url, this.headers_json)
        .then((response) => {
          this.categorias = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async saveMaterial() {
      // validaciones
      this.errors = [];
      if (!this.item.nombre) {
        this.errors.push("Debe indicar nombre del material");
      }
      // codigo articulo
      this.errors = [];
      if (!this.item.codigo_articulo) {
        this.errors.push("Debe indicar codigo");
      }
      if (!this.item.categorias_id) {
        this.errors.push("Debe seleccionar una categoria");
      }
      if (!this.item.unidad_medida) {
        this.errors.push("Debe seleccionar una categoria");
      }
      if (this.errors.length > 0) {
        this.validation_message = this.errors.join();
        this.showSnackBar(true);
        return;
      }
      let url = `${this.base_url}materiales`;
      let data = {
        nombre: this.item.nombre,
        codigo_articulo: this.item.codigo_articulo,
        categorias_id: this.item.categorias_id,
        unidad_medida: this.item.unidad_medida,
      };
      if (this.item.id > 0) {
        url = url + "/" + this.item.id;
        await this.axios
          .put(url, data, this.headers_json)
          .then((response) => {
            let index = this.listBody.findIndex((i) => i.id == this.item.id);
            this.listBody.splice(index, 1, response.data);
            this.showSnackBar(false);
          })
          .catch((error) => {
            this.validation_message = error;
            this.showSnackBar(true);
          });
      } else {
        await this.axios
          .post(url, data, this.headers_json)
          .then((response) => {
            this.listBody.push(response.data);
            this.showSnackBar(false);
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.validation_message = this.errors.join();
            this.showSnackBar(true);
          });
      }
      this.cleanForm();
    },
    editMaterial(item) {
      //console.log('acopio editar',item);
      this.index = this.listBody.findIndex((a) => a.id == item.id);
      this.item = Object.assign({}, item);
      this.dialog = true;
      this.title = "Editar Material";
    },
    async deleteMaterial(item) {
      this.$confirm("Desea borrar este material?").then((res) => {
        if (res) {
          let url = `${this.base_url}materiales/${item.id}`;
          this.axios
            .delete(url, this.headers_json)
            .then((response) => {
              console.log(response);
              this.loadMateriales();
              this.showSnackBar(false);
            })
            .catch((error) => {
              this.validation_message = error;
              this.showSnackBar(true);
            });
        }
      });
    },
    async uploadMateriales() {
      //this.headers.headers["Content-Type"] = "multipart/form-data";
      let url = `${this.base_url}uploads/materiales`;
      let formData = new FormData();
      formData.append("archivo_xlsx", this.file);
      let response = null;
      try {
        response = await this.axios.post(url, formData, this.headers_file);
        this.file = null;
        this.upload = false;
        this.loadMateriales();
        this.loadCategorias();
        this.showSnackBar(false);
      } catch (error) {
        response = error.response;
        console.log(response);
        let msg = response.data.data;
        this.validation_message =
          response.status + ": " + response.data.message + ". " + msg;
        this.showSnackBar(true);
      }
    },
    showSnackBar(error) {
      if (error) {
        this.color = "red";
      } else {
        this.color = "green";
        this.validation_message = "Ejecutado exitosamente!";
      }
      this.snackbar = true;
    },
    closeModal() {
      this.cleanForm();
      this.dialog = false;
      this.error = null;
      this.title = "Nueva Área";
    },
    cleanForm() {
      this.item.id = 0;
      this.item.codigo_articulo = "";
      this.item.nombre = "";
      this.item.categorias_id = null;
      this.item.unidad_medida = "";
      this.dialog = false;
    },
    ...mapMutations(["actualizarBaseUrl", "mostrarLoading", "ocultarLoading"]),
  },
  computed: {
    ...mapState(["loading", "base_url", "headers_json","headers_file"]),
  },
  components: {
    datatable: DataTable,
  },
};
</script>
